import {
    ArrayInput,
    AutocompleteInput,
    BooleanInput,
    required,
    ReferenceInput,
    SimpleFormIterator,
    SelectInput,
    TextInput,
    TabbedForm,
    FormTab,
    usePermissions,
    useGetIdentity,
    useDataProvider,
    useTranslate,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    Button,
    useNotify,
} from 'react-admin';
import { useForm } from 'react-final-form';
import RRuleIPnput from '../fields/rruleInput';
import DateInput from '../fields/dateInput';
import config from '../config';
import { TinyMCEInput } from '../fields/tinymceinput';
import { GMapInput } from '../fields/gmap';
import S3FileInput from '../fields/s3-input';
// import EventCategoryReferenceInput from './EventCategoryReferenceInput';
import AreaReferenceInput from './AreaReferenceInput';
import CouponReferenceInput from './CouponReferenceInput';
import ClaimedCouponReferenceInput from './ClaimedCouponReferenceInput';
import { customReferenceParseFormat } from '../helpers/utils';
import Save from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core';
import axios from 'axios';
import { useState } from 'react';

const ListingReferenceInput = () => {
    const { permissions: { role } = { role: 'editor' } } = usePermissions();
    const { identity } = useGetIdentity();
    const dataProvider = useDataProvider();
    const form = useForm();

    return (
        <ReferenceInput
            source="listingId"
            reference="listings"
            filter={role === 'editor' && identity ? { clientId: identity.id } : null}
            perPage={150}
            sort={{ field: 'title', order: 'ASC' }}
            allowEmpty
            validate={[required()]}
            onChange={async (id) => {
                if (id) {
                    const result = await dataProvider.getOne('listings', { id });
                    if (result.data && result.data.coordinates) {
                        form.change('coordinates', result.data.coordinates);
                    }
                }
            }}
        >
            <AutocompleteInput optionText="title" />
        </ReferenceInput>
    );
};

const userIdsThatCanEditSeats = [3040];
const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: 'auto',
    },
    eventCategories: {
        flex: '0 0 auto',
    },
});

export default function Form({ formFor, ...props }) {
    const { permissions: { role } = { role: 'editor' } } = usePermissions();
    const t = useTranslate();
    const { identity = {} } = useGetIdentity();
    const classes = useStyles();
    const notify = useNotify()
    const [loading, setLoading] = useState(false);

    let referenceProps = {};
    // if (formFor === 'edit') {
    referenceProps = { ...customReferenceParseFormat };
    // }

    const handleClick = async () => {
        if (window.confirm('Είστε σίγουροι;')) {
            setLoading(true);
            try {
                await axios.post(`${config.apiUrl}/events/${props.record.id}/copy-event-categories`);
                notify('resources.events.copyEventCategoriesSuccess', 'success');
                setLoading(false);
            } catch {
                notify('resources.events.copyEventCategoriesError', 'warning');
            }
        }
    };

    const validateCreateDate = (date) => {
        const errors = {};
        if (props.startDate) {
            if (new Date(date) <= new Date(props.startDate)) {
                errors.endsAt = 'End date should be after start date';
            }
        }
        return errors;
    };
    const validateEditDate = (date) => {
        const errors = {};
        if (props.record.startsAt) {
            if (new Date(date) <= new Date(props.record.startsAt)) {
                errors.endsAt = 'End date should be after start date';
            }
        }
        return errors;
    };

    return (
        <TabbedForm {...props}>
            <FormTab label="common.summary">
                <TextInput source="title" label="common.title" fullWidth validate={[required()]} />
                <TextInput source="url" fullWidth />
                {role !== 'editor' && (
                    <ReferenceInput source="eventId" reference="events" filter={{ eventId: null }} allowEmpty fullWidth>
                        <AutocompleteInput optionText={(event) => (event ? `${event.title} (id: ${event.id})` : '')} />
                    </ReferenceInput>
                )}
                {role !== 'editor' && (
                    <ReferenceInput
                        allowEmpty
                        source="clientId"
                        label="common.client"
                        reference="users"
                        validate={[required()]}
                    >
                        <AutocompleteInput
                            optionText={(user) => (user ? `${user.firstName || ''} ${user.lastName || ''}` : '')}
                        />
                    </ReferenceInput>
                )}
                <ListingReferenceInput />
                <ReferenceInput source="categoryId" reference="categories">
                    <AutocompleteInput
                        optionText={(category) => (category ? `${category.title} (${category.parent.title})` : '')}
                    />
                </ReferenceInput>
                <div source="eventCategories" className={classes.root}>
                    <div>
                        <ReferenceArrayInput
                            source="eventCategories"
                            reference="eventcategories"
                            label={t('resources.eventcategories.name', { smart_count: 2 })}
                            {...referenceProps}
                        >
                            <AutocompleteArrayInput optionText={(category) => (category ? `${category.title}` : '')} />
                        </ReferenceArrayInput>
                    </div>
                    {formFor === 'edit' &&
                        role !== 'editor' &&
                        props.record.events &&
                        props.record.events.length > 0 &&
                        !props.record.eventId && (
                            <Button
                                disabled={loading}
                                onClick={handleClick}
                                label={t('resources.events.copyEventCategories')}
                            >
                                <Save />
                            </Button>
                        )}
                </div>
                {role !== 'editor' && (
                    <ReferenceInput
                        label={t('resources.theaters.name', { smart_count: 1 })}
                        source="theaterId"
                        reference="theaters"
                        allowEmpty
                    >
                        <AutocompleteInput optionText={(t) => (t ? `${t.name}` : '')} />
                    </ReferenceInput>
                )}
                <TextInput source="venue" />
                <AreaReferenceInput source="areaId" reference="areas" />
                {role !== 'editor' && <BooleanInput source="isApproved" label="common.isApproved" />}
                {role !== 'editor' && (
                    <SelectInput
                        source="status"
                        label="common.status"
                        choices={[
                            { id: 0, name: 'Not Active' },
                            { id: 1, name: 'Active' },
                        ]}
                    />
                )}
            </FormTab>
            {formFor === 'create' && (
                <FormTab label="resources.events.fields.dates">
                    <DateInput
                        source="startsAt"
                        label="common.startsAt"
                        onChange={(date) => props.setStartDate(date)}
                        validate={[required()]}
                    />
                    <DateInput source="endsAt" label="common.endsAt" validate={[required(), validateCreateDate]} />
                    <ArrayInput source="recurringDates">
                        <SimpleFormIterator>
                            <DateInput source="startsAt" label="common.startsAt" />
                            <DateInput source="endsAt" label="common.endsAt" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <RRuleIPnput source="rrule" label="resources.events.fields.rrule" startDate={props.startDate} />
                </FormTab>
            )}
            {formFor === 'edit' && (
                <FormTab label="resources.events.fields.dates">
                    <DateInput source="startsAt" validate={[required()]} label="common.startsAt" />
                    <DateInput source="endsAt" validate={[required(), validateEditDate]} label="common.endsAt" />
                </FormTab>
            )}
            <FormTab label="common.image">
                <S3FileInput
                    source="image"
                    apiRoot={config.apiUrl}
                    uploadOptions={{
                        server: config.apiUrl,
                        signingUrl: '/s3/sign',
                    }}
                    validate={[required()]}
                />
            </FormTab>
            <FormTab label="common.description">
                <TinyMCEInput source="description" label="common.description" />
            </FormTab>
            <FormTab label="common.coordinates">
                <GMapInput source="coordinates" />
            </FormTab>
            <FormTab label="common.meta">
                <TextInput source="metaDescription" label="common.metaDescription" multiline fullWidth />
                <TextInput source="metaKeywords" label="common.metaKeywords" fullWidth />
            </FormTab>
            {formFor === 'edit' && (role !== 'editor' || userIdsThatCanEditSeats.indexOf(identity.id) > -1) && (
                <FormTab label="resources.events.fields.tickets" path="coupons">
                    <TextInput source="totalSeats" label="Συνολικές Θέσεις" multiline fullWidth />
                    <CouponReferenceInput />
                </FormTab>
            )}
            {formFor === 'edit' && (
                <FormTab label="Κρατήσεις">
                    <ClaimedCouponReferenceInput />
                </FormTab>
            )}
        </TabbedForm>
    );
}
